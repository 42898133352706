import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";

import ContentSlider from "../../components/GSTTemp/ContentSlider";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function ImportExportCode() {
  /* Slider */
  // const importSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83`,
  //     header: `Import Export Code Registration Service In Hosur`,
  //     content: `Online Import-Export-Code Registration with TODAYFILINGS Experts`,
  //     image: "/imgs/registration/iec/iec-slider.png",
  //     alt_tag: "Best Online IE Code Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Import Export Code`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    f_price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data With Form*/
  const IecAboutData = {
    header: `IEC Registration Online`,
    sub_title: `FOR PERSONAL AND BUSINESS RELATED IEC REGISTRATION.`,
    content_paragraph: [
      `Apply for IE Code Registration with us online! All Indian businesses
      who engage in or want to extend their business operations through import
      and export business operations are required to do so the DGFT,
      under the Department of Commerce, in India, issues
      IEC Code Registration online, a compulsory 10-digit unique licence,
      for corporations and businesses that deal with import-export transactions in the
      Indian Territory.`,

      // `It just takes one time to register for an import export code for people
      // or IEC in India. Once granted, the licence can be used by the business
      // the duration of its existence dont need for renewal.`,
    ],
  };

  /* Scroll Nav Data */
  const IecScrollId = [
    {
      id: `#ice`,
      heading: `ICE registration`,
    },
    {
      id: `#regprocedure`,
      heading: `Registration Procedure`,
    },
    {
      id: `#documents`,
      heading: `Documents Required`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const IecIcData = {
    id: 'ice',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'When IEC Registration In India Is Necessary',
    image: '/imgs/registration/iec/iec-reg.png',
    alt_tag: 'IEC Registration Procedure',
    points: [
      {
        content: `Only if you register with an IEC number as a trader or importer will customs authorities permit your shipment.`,
        icon: true,
      },
      {
        content: `For international transactions, the bank requires an IEC registration code.`,
        icon: true,
      },
      {
        content: `IEC registration at the customs port is required for exporters in order to have their cargo cleared.`,
        icon: true,
      },
      {
        content: ` When an exporter receives money in foreign currency into his bank account without any problems, IEC registration in India code is necessary.`,
        icon: true,
      },
      {
        content: `The government of the foreign countries confirms the dealer on the basis of the IEC code application process.`,
        icon: true,
      },
      {
        content: `The export of Special Chemicals, Organisms, Materials, Equipment and supplies, and Technologies (SCOMET) mentioned in Appendix –3,
        Schedule 2 of ITC will not be permitted without an exemption from obtaining IEC (HS).`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const IecCfData = {
    id: 'regprocedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Registration Procedure of IEC Registration in India',
    content: `Following Is The Step By Step Process Of IEC Registration:`,
    body: [
      {
        points: `ISO Visit the website for the Directorate General of Foreign Trade ( www.dgft.gov.in ).
        Then you must select "Apply for New Import Export Code (IEC) or Modify Existing IEC" from the menu.`,
        icon: true,
      },
      {
        points: `Complete the applicant's PAN information. After that, provide the required information,
        including Name (as it appears on your PAN), Date of Incorporation or Date of Birth, and Captcha Code.`,
        icon: true,
      },
      {
        points: `The next step is to provide the email address and mobile number. Afterward, hit "Generate OTP" after
        entering the Captcha code. Enter and submit the OTP.`,
        icon: true,
      },
      {
        points: `The next step is the creation of an E-Commerce number, at which point you must enter the following information:
        the company's name, address, previously entered email and mobile numbers, the name on the PAN card, the date of incorporation,
        and the type of company, such as a private limited company, a public limited company, a proprietorship, an LLP, an undertaking, etc.`,
        icon: true,
      },
      {
        points: `Business ventures undertaken by the company, including those as a manufacturer exporter, service provider, and merchant
         exporter. Name of the account holder, bank account number, and applicant information.`,
        icon: true,
      },
      {
        points: `You must fill out the branch information for the business or organization on the next page, including the branch's SI number,
        six-digit PIN, and address. update the director, partner, proprietor, karta, and managing trustee information.`,
        icon: true,
      },
      {
        points: `After filling out all the necessary information, upload the necessary files, such as an address proof, a voided check, or a
        bank certificate. The payment and verification of fees, as outlined in the application, come next.`,
        icon: true,
      },
      {
        points: `Submit the application after filling all the necessary information.Click Print Application after thoroughly reviewing the
         finished application in the preview window.The applicant's DSC may be included in the application, or it may not.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const ImportExportImages = [
    '/imgs/registration/iec/iec.png',
    '/imgs/registration/iec/iec-img.png',
    '/imgs/registration/iec/iec-img-slide.png',
  ];

  /*  Slider Content Component Data */
  const IecSCData = {
    id: 'documents',
    background: [],
    mt_div: 'mt-3',
    header: 'Documents Required For IEC Registration In India',
    content: [
      {
        points: `PAN Card of the company or the applicant.`,
        icon: true,
      },
      {
        points: `Bank account statement of the company or applicant.`,
        icon: true,
      },
      {
        points: `Photocopy of Driving License.`,
        icon: true,
      },
      {
        points: `Photocopy of the cancelled cheque.`,
        icon: true,
      },
      {
        points: `Details of A copy of Aadhar/Voter ID.`,
        icon: true,
      },
      {
        points: `Certificate of Partnership Deed/Incorporation.`,
        icon: true,
      },
      {
        points: `The business operations of the enterprise- "Service." or "Manufacturing.Address proof of the registered office premise.`,
        icon: true,
      },
    ],
    images: ImportExportImages,
  };

  /*  Content Img Component Data */
  const IecCiData = {
    id: 'benefits',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Benefits Of IEC Registration Online In India',
    paragraph: `Business expansion: Using IEC, a company can broaden its customer base to include markets around the world.
    The IEC License enables the company to export its goods outside of the country and build brand awareness on the global market.`,
    points: [
      {
        content: `TReceiving Several Benefits: The business may be eligible for a number of benefits from the Export Promotion Council,
         DGFT, and Customs depending on the basis of IEC registration.`,
        icon: true,
      },
      {
        content: `Filing of a ReturnThere is no requirement to file a return for the iec code online application. Once online iec registration
         in India is approved, no additional steps are required to verify its validity. Anytime an export transaction occurs,
        the DGFT doesn't become involved or raise any concerns about filing a return.`,
        icon: true,
      },
      {
        content: `Valid for LifetimeOnce your company has obtained an IEC license, you can benefit from its automatic iec registration
        features indefinitely because the permit is perpetual and, more significantly, there is no requirement for a renewal procedure.`,
        icon: true,
      },
      {
        content: `Government-Authorized ProofThe Import Export Code (IEC), which is issued by the DGFT, is a government identification number.
         and be able to present this identifying code number when the package is cleared.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/iec/iec-benefits.png',
    alt_tag: 'Benefits for Iec Registration',
  };

  /* Faq data */
  var ImportExportFAQ = [
    {
      header: 'What is an IEC?',
      body: [
        {
          content: `Import Export Code  (in short IEC Code) is a ten digit number with permanent validity granted by
          Directorate General of Foreign Trade under Ministry  of Commerce and Industry, to any bonafide person/ company
          for carrying out import/export. Importers are not allowed to proceed without this code and exporters can’t
           take advantage of exports from DGFT, customs, Export Promotion Council, if they don’t have this code.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the advantages of IEC?',
      body: [
        {
          content: `You can benefit from a number of DGFT, Customs, export promotion
          council, and other things by using IEC Registration.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can Export/Import be made without IEC?',
      body: [
        {
          content: `No. IEC forms a primary document for recognition by Govt. of India as an Exporter/ Importer`,
          icon: false,
        },
      ],
    },
    {
      header: 'How can I modify my IEC application?',
      body: [
        {
          content: `An application can be modified any number of times till it is submitted for processing. You need to login with the same details like PAN, mobile and email again.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is PAN Number or PAN card essential or what are the alternatives for IE code registration?',
      body: [
        {
          content: `Yes, for IEC Code registration, PAN card Number is mandatory. Self certified photocopy of PAN Card of the applicant has to be submitted with the application. `,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Is it mandatory to provide a PAN Card?',
      body: [
        {
          content: ` Yes, submitting a PAN card is required. Your PAN Card must be
          self-attested and submitted with the application.`,
          icon: true,
        },
      ],
    },
    {
      header: ' Is the requirement of IEC Registration, product-based?',
      body: [
        {
          content: `No, Import Export Code is not product-based and has a perpetual
          licence.`,
          icon: false,
        },
      ],
    },
    {
      header: 'From where one can get IEC Form?',
      body: [
        {
          content: `It can be downloaded from the download section of DGFT website under the link APPENDIX & AAYAAT NIRYAAT FORM ( ANF2A). `,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'Is there any need to formalize or renew at any point in time?',
      body: [
        {
          content: `No, the IEC Registration is valid for a lifetime. So there is no
          need to renew it.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'What to do to get a new or amended IEC?',
      body: [
        {
          content: `On the official page, you may see the current status of your BIN, or
          business identification number.`,
          icon: false,
        },
      ],
    },
  ];

  return (
    <div>
      <Seo
        title='Import Export Code'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={importSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={IecAboutData} />

          <Counter />
          <ScrollNav scroll_data={IecScrollId} />

          <ImgContent item={IecIcData} />

          <ContentForm CFSection_data={IecCfData} />

          <ContentSlider CsSection_data={IecSCData} />

          <ContentImg CISection_data={IecCiData} />
          <Cta />

          <Guidance />

          <FAQAccord items={ImportExportFAQ} />
          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
